import React, { Component } from "react";
import Checkout from "../classes/checkout";
import withUnmounted from '@ishawnwang/withunmounted'
import ls from "local-storage";
import googleInstance from "../classes/google"
import {connect} from 'react-redux';
import Places from "../classes/places";
ls.backend(sessionStorage);

const mapStateToProps = (state) => {
  return {
      llConfig: state.llConfig,
      storeCountries: state.countries
  };
}

class ThankyouPage extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.createMarkup = this.createMarkup.bind(this);
    this.parseQryStr = this.parseQryStr.bind(this);
    this.returnToStore = this.returnToStore.bind(this);
    this.state = { 
      random: 0,
      order_id:'',
      slug:false,
      customerInfo : ls.get('customerInfo'),
      address2 : (ls.get('customerInfo').address2 !== "") ? ls.get('customerInfo').address2 + " <br/>" : ls.get('customerInfo').address2,
      shippingId : (ls.get('customerInfo').shipping) ? ls.get('customerInfo').shipping : 0,
      shippingName : "",
      countryId : (ls.get('customerInfo').country) ? ls.get('customerInfo').country : "",
      countryName : "",
      stateId : (ls.get('customerInfo').state) ? ls.get('customerInfo').state : "",
      stateName : "",
      ordertotal : (ls.get('ordertotal')) ? ls.get('ordertotal') : "0.00",
      billingaddress : (ls.get("billingAddress")) ? ls.get("billingAddress") : "",
      billingaddress2 : (ls.get("billingAddress")) ? ls.get("billingAddress").billingAddress2 : "",
      billingcountryId : (ls.get("billingAddress")) ? ls.get("billingAddress").billingCountry : "",
      billingcountryName : "",
      billingstateId : (ls.get("billingAddress")) ? ls.get("billingAddress").billingState : "",
      billingstateName : "",
      orderDetail:false,
      userName: "",
      billingUserName : ""
    };
    if(this.state.billingaddress2 !== ""){
      this.setState( { billingaddress2 : this.state.billingAddress2 + " <br/>" } )
    }
  }

  parseQryStr( queryString ) {
    try{
      var params = {}, queries, temp, i, l;
      queries = queryString.split("&");
        for ( i = 0, l = queries.length; i < l; i++ ) {
          temp = queries[i].split('=');
            params[temp[0]] = temp[1].split(',');  
        } 
      return params;
    }catch(e){
      return false;
    }
}

  async componentWillMount() {

    
    this.props.setPopException(false)
    this.handleClick();
    let order_id =  Checkout.getOrderConfirmId();

    const { history } = this.props; 
    let slug = await this.returnToStore(order_id);
    if(!order_id && slug === false){
      history.push("/");
    } 
    this.setState({order_id:order_id,slug:slug});
    
    await googleInstance.beginProcess('purchase');
    if(this.state.shippingId > 0){
      //shippingOnCheckoutConfig
      var shippingName = "";
      if(this.props.cartInfo && this.props.cartInfo.shippingMethods && Object.keys(this.props.cartInfo.shippingMethods).length > 0){
        Object.keys(this.props.cartInfo.shippingMethods).forEach((key) => {
          if(this.state.shippingId === this.props.cartInfo.shippingMethods[key]['shipping_method_crm_id']){
            shippingName = this.props.cartInfo.shippingMethods[key].shipping_method_name;
          }
        })
      }

      let shippingOnCheckoutConfig = ls.get("shippingOnCheckoutConfig");
      if(shippingName === "" && shippingOnCheckoutConfig && shippingOnCheckoutConfig.length>0){
        Object.keys(shippingOnCheckoutConfig).forEach((key) => {
          if(this.state.shippingId === shippingOnCheckoutConfig[key]['shipping_method_crm_id']){
            shippingName = shippingOnCheckoutConfig[key].shipping_method_name;
          }
        })
      }

      this.setState({ shippingName :  shippingName})

    }
    await googleInstance.beginProcess('purchase');
    let orderDetail = await Checkout.getOrderDetail(order_id);
    let userName = (orderDetail && orderDetail.shipping_first_name && orderDetail.shipping_last_name) ? orderDetail.shipping_first_name + ' ' + orderDetail.shipping_last_name : ""; 
    let billingUserName = (orderDetail && orderDetail.billing_first_name && orderDetail.billing_last_name) ? orderDetail.billing_first_name + ' ' + orderDetail.billing_last_name : ""; 
    this.setState({order_id:order_id,orderDetail:orderDetail, userName : userName, billingUserName : billingUserName});

    
    

    if(this.state.countryId){
      let countrylist = await Places.getStatesForThankPage(this.props.storeCountries.countries, this.state.countryId)
      this.setState({ countryName : countrylist.name })
      let stateList = countrylist.states
      if(Object.keys(stateList).length > 0){
        Object.keys(stateList).forEach((key) => {
          if(stateList[key].code === this.state.stateId){
            this.setState({ stateName : stateList[key].name })
          }
        })
      }
    }

    if(this.state.billingcountryId){
      let countrylist = await Places.getStatesForThankPage(this.props.storeCountries.countries, this.state.billingcountryId)
      this.setState({ billingcountryName : countrylist.name })
      let stateList = countrylist.states
      if(Object.keys(stateList).length > 0){
        Object.keys(stateList).forEach((key) => {
          if(stateList[key].code === this.state.billingstateId){
            this.setState({ billingstateName : stateList[key].name })
          }
        })
      }
    }
  }

  returnToStore(order_id){
    try{
      let ll_hash =this.props.llConfig.llHash;
      const params = this.parseQryStr(ll_hash);
      let slug = params && "slug" in params && params.slug && params.slug.length>0 ? params.slug[0] : false; 
      
      if(!order_id && slug !== false){
        window.location = "//"+slug+"?action=order_success";
      }
      return slug;
    }catch(e){
      return false;
    }
  }




  
  
  handleClick() {
    const min = 1001;
    const max = 3000;
    const rand = min + Math.random() * (max - min);
    this.setState({ random: parseInt(this.state.random + rand) });
  }

  createMarkup(text) { return {__html: text}; };

  render() {
    return (
      
      <div className="">
      {this.redirectCount}
        <div className="contact-information">
          <div className={`change-contact ${this.props.checkout_enable_single_page ? 'op-border-black' :'notonepage'}`}>
            <div className="bottom-border-div order-confirmation">
              <h2 id="main-header" >
                
                Your order is confirmed #{this.state.order_id}
              </h2>
              
              
            </div>

            {this.state.orderDetail && <div className="customer-info-d">
                <div className="customer-Info">
                  <div className="partition">
                    <div className="cntct-info">
                      <h4>Contact Information</h4>
                      <p className={"thank-you-label " + ( this.state.orderDetail.email_address.length > 24 ? "custom-tooltip": "")} data-title={this.state.orderDetail.email_address}>{ Checkout.setEcllipseOnString(this.state.orderDetail.email_address, 24) }</p>
                    </div>
                    <div className="shpng-info">
                      <div className="shpng-addr">  
                        <h4>Shipping Address</h4>
                        <p className={"thank-you-label " +( this.state.userName.length > 24 ? "custom-tooltip": "")} data-title={this.state.userName}>
                          { Checkout.setEcllipseOnString(this.state.userName, 24) }
                        </p>
                        <p>
                          {this.state.orderDetail.shipping_street_address}
                          <br/>
                          <div dangerouslySetInnerHTML={{ __html: this.state.orderDetail.shipping_street_address2 }}></div>
                          {this.state.orderDetail.shipping_city}, {(this.state.orderDetail.shipping_state !== "" ? this.state.orderDetail.shipping_state : this.state.orderDetail.shipping_state )} - {this.state.orderDetail.shipping_postcode}
                          <br />
                          {(this.state.orderDetail.shipping_country !== "" ? this.state.orderDetail.shipping_country : this.state.orderDetail.shipping_country )}
                        </p>
                      </div>
                      <div className="shpng-mthd">  
                        <h4>Shipping Method</h4>
                        {this.props.cartInfo.token_based_user_free_shipping ?
                        <p className="thank-you-label">Free Shipping</p>
                        :
                        <p className={"thank-you-label " +( this.state.shippingName.length > 24 ? "custom-tooltip": "")} data-title={ this.state.shippingName !== "" ? this.state.shippingName : "" }>{ this.state.shippingName !== "" ? Checkout.setEcllipseOnString(this.state.shippingName,30) : "" }</p>
                        }
                        </div>
                    </div>
                  </div>
                  <div className="partition">
                    <div className="cntct-info">
                      <h4>Payment Method</h4>
                      <p>{ (ls.get('paymentType')) ? ls.get('paymentType') : "Card Checkout" }</p>
                    </div>
                    <div className="cntct-info">
                      <h4>Billing Address</h4>
                           
                              <p className={"thank-you-label " +( this.state.billingUserName.length > 24 ? "custom-tooltip": "")} data-title={this.state.billingUserName}>
                                  { Checkout.setEcllipseOnString(this.state.billingUserName,24) }
                              </p>
                              <p>
                                {this.state.orderDetail.billing_street_address}
                                <br />
                                <div dangerouslySetInnerHTML={{ __html: this.state.orderDetail.billing_street_address2 }}></div>
                                {this.state.orderDetail.billing_city}, {(this.state.orderDetail.billing_state !== "" ? this.state.orderDetail.billing_state : this.state.orderDetail.billing_state )} - {this.state.orderDetail.billing_postcode}
                                <br />
                                {(this.state.orderDetail.billing_country !== "" ? this.state.orderDetail.billing_country : this.state.orderDetail.billing_country )}
                              </p>
                            
                          
                            
                    </div>
                  </div>
                </div>
            </div>}  

          </div>
            {this.props.cartInfo && this.props.cartInfo.checkout_order_receipt_html && <div className="change-contact">
            <div className="bottom-border-div order-confirmation"><div dangerouslySetInnerHTML={this.createMarkup(this.props.cartInfo.checkout_order_receipt_html)}></div></div></div>}          
        </div>

        {this.state.slug !== false && <div className="row">
              <div className="col-sm-12">
                <span onClick={()=>this.returnToStore(null)} className="float-right stretched-link font-weight-bold mt-4 return-to-store span-cursor">Continue shopping {`>`}</span>
              </div>
          </div>}
          
      </div>
    );
  }
}

const ConnectedThankyouPage = connect(
  mapStateToProps,
  null
)(ThankyouPage);

export default withUnmounted(ConnectedThankyouPage);
