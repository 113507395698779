import React, { Component } from "react";
import withUnmounted from '@ishawnwang/withunmounted'
import swal from 'sweetalert'
import ls from "local-storage";
ls.backend(sessionStorage);

class TandC extends Component {
    constructor(props) {
        super(props);
        this.openTNCpopup = this.openTNCpopup.bind(this);
        this.createMarkup = this.createMarkup.bind(this);
        this.changeStatus = this.changeStatus.bind(this);

        this.eventListeners = new Map();
        this.addEvent = this.addEvent.bind(this);

        this.state = {
            cartInfo: this.props.cartInfo,
            tndStatusVal: ls.get('tandc_status')
        }  
    }


    componentDidMount(){
      this.addEvent('click', this.openTNCpopup.bind(this));
    }

    addEvent (eventName, callback) {

      try{
        if(document.getElementById('tndc')){
          document.getElementById('tndc').addEventListener(eventName, callback, false);
          this.eventListeners.set(`document:${eventName}`, { eventName, callback })
        }
      }catch(e){
          console.log("Exception",e);
      }
      
    }

    createMarkup(text) { return {__html: text}; };

    async openTNCpopup(){
        let tncDetail = this.state.cartInfo && this.state.cartInfo.tandc_details
      
        if(tncDetail){
            const wrapper = document.createElement('div');
            wrapper.innerHTML = tncDetail;
            swal({
              title: "Terms conditions",
              content: wrapper, 
              className:"demo-class",
              button: {
                text: "I Agree",
              },
            }
            ).then((e)=>{
              //further handler
            });    
        }
    }

    changeStatus(){
      let status = !this.state.tndStatusVal;
      this.setState({tndStatusVal : status});
      ls.set("tandc_status",status);
    }


    render() {
      var tndcDetail = this.state.cartInfo && this.state.cartInfo.tandc_iagree_detail ? this.state.cartInfo.tandc_iagree_detail : "I agree to the {terms conditions}";
      if(tndcDetail !== ""){
        if(tndcDetail.includes("{") && tndcDetail.includes("}")){
          tndcDetail = tndcDetail.replace("{",'<a id="tndc" class="tndc-link">');
          tndcDetail = tndcDetail.replace("}",'</a>');
        }
      }

      tndcDetail = tndcDetail.replace("{","");
      tndcDetail = tndcDetail.replace("}","");

        return (
            <React.Fragment>
                {this.state.cartInfo &&  this.state.cartInfo.checkout_enable_tandc && this.state.cartInfo.tandc_details  && <div className="row">
                      <div className="col-sm-12">
                            <div className="contact-form tnc-form">
                              <div className="custom-control tnc-checkbox">
                                <input type="checkbox" className="form-check-input" id="styled-checkbox-2" value="1" checked={this.state.tndStatusVal} onChange={this.changeStatus}/>
                                <label className="form-check-label" htmlFor="styled-checkbox-2" dangerouslySetInnerHTML={this.createMarkup(tndcDetail)}></label> 
                              </div>
                            </div>
                      </div>
                    </div>}
            </React.Fragment>
            
        )
    }
}


export default withUnmounted(TandC);        