import React, { Component } from "react";
import Checkout from "../classes/checkout";
import { Formik } from "formik";
import * as Yup from "yup";
import withUnmounted from '@ishawnwang/withunmounted'
import swal from "sweetalert";
import googleInstance from "../classes/google"
import Footer from "../components/Footer"
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    return {
        llConfig: state.llConfig,
        storeCountries: state.countries
    };
}

const initialValues = {prospectId:"10"};

const validationSchema = Yup.object().shape({
});

class upsellPage extends Component{
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.createMarkup = this.createMarkup.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.eventListeners = new Map();
        this.addEvent = this.addEvent.bind(this);
        this.addEventNothanks = this.addEventNothanks.bind(this);
        this.state = {
            upsells:{},
            processUpsell:null,
            upsellIndex:0,
            loading: false
        }
    }

    addEvent (eventName, callback) {

        try{
            document.getElementById('process-upsell').addEventListener(eventName, callback, false);
            this.eventListeners.set(`document:${eventName}`, { eventName, callback })
        }catch(e){
            console.log("Exception",e);
        }
        
    }


    addEventNothanks(eventName, callback){
        try{
            document.getElementById('no-thanks').addEventListener(eventName, callback, false);
            this.eventListeners.set(`document:${eventName}`, { eventName, callback })
        }catch(e){
            console.log("Exception while",e);
        }
    }
    
    async formSubmit(e){
        e.preventDefault();
        swal("Order is processing.....", {
            icon: "warning",
            buttons:false
          });
        await this.handleSubmit(e, this.props,true);
        swal.close();
    }

    async noThanksSublit(e){
        try{
            e.preventDefault(); 
            await this.handleSubmit(e, this.props,false);
        }catch(e){
            //
        }
    }

    toggle() {
        this.setState({
          loading: !this.state.loading,
          progress: 0.5
        });
      }

    setProductNameStringForDefualtTemplate(){
        var ele = document.getElementsByClassName('cus-red custom-tooltip cutStr');
        if(ele.length > 0){
            var str = ele[0].innerText;
            if(str.length > 29){
                str = Checkout.setEcllipseOnString(str, 30);
                ele[0].innerText = str;
            }else{
                ele[0].className = "cus-red";
            }
        }
    }  

    async componentWillMount() {
        let llHash = this.props.llConfig.llHash;
        if(!this.props.llConfig.cartData || (this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_enable_single_page !== 1))
        {
          await this.props.initiateConfig(llHash);
        }
        this.props.setPopException(false) 
            let response = null;
            
            if(!response){
               
                response = await Checkout.getUpsells({});
                
                response = await Checkout.getUpsellDetail();
            }
            const { history } = this.props;
            let upsellIndex = await Checkout.getUpsellIndex();
            
            if(!response || upsellIndex==null){
                history.push("/thank-you");
                return 0;
            }
            
            await googleInstance.beginProcess('purchase');
            let processData = (response && response.products ? response.products[upsellIndex] : {});
            this.setState({ upsells: (response && response.products ? response.products:[] ) , processUpsell: processData})
            this.addEvent('click', this.formSubmit.bind(this));
            this.addEventNothanks('click',this.noThanksSublit.bind(this));
            
            this.setProductNameStringForDefualtTemplate();
    }

    async gotoThankyou(props){
        const { history } = props;
         history.push("/thank-you");
    }

    handleSubmit = async function(e, props, process) {
        
        e.preventDefault();
        let res = true

        this.toggle();
        
        if(process)
        {
            res = await Checkout.processUpsell(this.state.processUpsell);
        }
       
        let upsellLength = this.state.upsells ? Object.keys(this.state.upsells).length: 0;
        let savedIndex = await Checkout.getUpsellIndex();
        let upsellIndex = savedIndex ? parseInt(savedIndex) + 1 : 1;
        if(res === true &&  upsellIndex < upsellLength){
            await Checkout.setUpsellIndex(upsellIndex);
            this.setState({processUpsell: this.state.upsells[upsellIndex], upsellIndex : upsellIndex});
            this.setProductNameStringForDefualtTemplate();
            this.addEvent('click', this.formSubmit.bind(this));
            this.addEventNothanks('click',this.noThanksSublit.bind(this));
            await googleInstance.beginProcess('purchase');
        }else if(res === true){
            const { history } = props;
            history.push("/thank-you");
        }else{
            //for error
        }
      };

      createMarkup(text) { return {__html: text}; };
  
    render() {
        var processUpsell = this.state.processUpsell ? this.state.processUpsell :null; 
        
        return  processUpsell!=null  ?  
                (<Formik initialValues={initialValues} validationSchema={validationSchema}>
                    <div className={ (processUpsell !== null && processUpsell.is_default_template === true) ? 'container' : "" }>
                        <div className="upsell-pro-con" dangerouslySetInnerHTML={this.createMarkup(processUpsell.template)}></div>
                        { (processUpsell !== null && processUpsell.is_default_template === true) ? <Footer timerStart={this.props.timerStart} /> : "" }
                    </div>
                </Formik>)
                :
                (<Formik initialValues={initialValues} validationSchema={validationSchema}>
                    <div className="container upsellDiv" ></div>
                </Formik>)
    }
}

const ConnectedupsellPage = connect(
    mapStateToProps,
    null
)(upsellPage);

export default withUnmounted(ConnectedupsellPage);
